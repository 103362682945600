import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CarItemComponent } from '../car-item/car-item.component';
import { DividerComponent } from '../divider/divider.component';
import { CarStatItemComponent } from '../car-stat-item/car-stat-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-car-dialog',
  imports: [
    MatDialogModule,
    MatButtonModule,
    CarItemComponent,
    MatDividerModule,
    DividerComponent,
    CarStatItemComponent,
    MatTooltipModule,
  ],
  templateUrl: './car-dialog.component.html',
  styleUrl: './car-dialog.component.scss',
})
export class CarDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
