import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterModule } from '@angular/router';
import { CarApiService } from '../../services/car-api.service';
import { ManufacturerImagePipe } from '../../pipes/manufacturer-image.pipe';
import { NgOptimizedImage } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CarManufacturerJumpDialogComponent } from '../../components/car-manufacturer-jump-dialog/car-manufacturer-jump-dialog.component';
import { Horizon5DownloadDialogComponent } from '../../components/horizon5-download-dialog/horizon5-download-dialog.component';
import { PlatformService } from '../../services/platform.service';

@Component({
    selector: 'app-car-tabs-page',
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        ManufacturerImagePipe,
        NgOptimizedImage,
    ],
    templateUrl: './car-tabs-page.component.html',
    styleUrl: './car-tabs-page.component.scss'
})
export class CarTabsPageComponent implements OnInit {
  constructor(
    private api: CarApiService,
    private title: Title,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.title.setTitle('Forza Horizon 5 Full Car List - Horizon 5 Cars');
  }

  ngOnInit(): void {
    this.findManufacturers();
  }

  manufacturers: any[] = [];
  loading: boolean = false;
  async findManufacturers() {
    try {
      this.loading = true;
      const r = await this.api.findManufacturers();
      this.manufacturers = r || [];
      if (this.manufacturers.length > 0 && this.router.url === '/cars') {
        this.router.navigate(['/cars', this.manufacturers[0].name], {
          replaceUrl: true,
        });
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  jump() {
    this.dialog.open(CarManufacturerJumpDialogComponent, {
      data: this.manufacturers,
      width: '640px',
      autoFocus: false,
    });
  }

  download() {
    this.dialog.open(Horizon5DownloadDialogComponent, {
      width: '360px',
      autoFocus: false,
    });
  }
}
