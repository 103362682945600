import { Component, OnInit } from '@angular/core';
import { CarApiService } from '../../services/car-api.service';
import { SwiperOptions } from 'swiper/types';
import { NgxSwiperjsModule } from '@wikylyu/ngx-swiperjs';

import {
  Mousewheel,
  Navigation,
  Pagination,
  A11y,
  Autoplay,
  Scrollbar,
  FreeMode,
} from 'swiper/modules';
import { CarImagePipe } from '../../pipes/car-image.pipe';
import { NgOptimizedImage } from '@angular/common';
@Component({
    selector: 'app-random-car-swiper',
    imports: [NgxSwiperjsModule, CarImagePipe, NgOptimizedImage],
    templateUrl: './random-car-swiper.component.html',
    styleUrl: './random-car-swiper.component.scss'
})
export class RandomCarSwiperComponent implements OnInit {
  constructor(private api: CarApiService) {}
  public config: SwiperOptions = {
    modules: [
      Navigation,
      Pagination,
      A11y,
      Mousewheel,
      Autoplay,
      Scrollbar,
      FreeMode,
    ],
    autoHeight: true,
    loop: true,
    spaceBetween: 20,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    freeMode: {
      enabled: true,
    },

    navigation: true,
    pagination: { clickable: true, dynamicBullets: true },
    slidesPerView: 'auto',
    centeredSlides: true,
    breakpoints: {
      400: {
        slidesPerView: 'auto',
        centeredSlides: false,
      },
    },
  };

  async findRandomCars() {
    try {
      this.cars = await this.api.findRandomCars();
    } catch (error) {}
  }

  cars: any[] = [];

  ngOnInit(): void {
    this.findRandomCars();
  }
}
