import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSwiperjsModule } from '@wikylyu/ngx-swiperjs';
import { ManufacturerImagePipe } from '../../pipes/manufacturer-image.pipe';
import {
  A11y,
  Autoplay,
  FreeMode,
  Mousewheel,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { CarApiService } from '../../services/car-api.service';
@Component({
    selector: 'app-random-manufacturer-swiper',
    imports: [NgxSwiperjsModule, ManufacturerImagePipe, NgOptimizedImage],
    templateUrl: './random-manufacturer-swiper.component.html',
    styleUrl: './random-manufacturer-swiper.component.scss'
})
export class RandomManufacturerSwiperComponent implements OnInit {
  public config: SwiperOptions = {
    modules: [
      Navigation,
      Pagination,
      A11y,
      Mousewheel,
      Autoplay,
      Scrollbar,
      FreeMode,
    ],
    autoHeight: true,
    loop: true,
    spaceBetween: 20,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    freeMode: {
      enabled: true,
    },

    navigation: true,
    pagination: { clickable: true, dynamicBullets: true },
    slidesPerView: 'auto',
    centeredSlides: true,
    breakpoints: {
      400: {
        slidesPerView: 'auto',
        centeredSlides: false,
      },
    },
  };

  constructor(private api: CarApiService) {}

  async findRandomManufacturers() {
    try {
      this.manufacturers = await this.api.findRandomManufacturers();
    } catch (error) {}
  }

  manufacturers: any[] = [];

  ngOnInit(): void {
    this.findRandomManufacturers();
  }
}
