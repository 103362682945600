import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

interface GroupData {
  name: string;
  children: any[];
}
@Component({
    selector: 'app-car-manufacturer-jump-dialog',
    imports: [MatDialogModule, MatButtonModule, MatButtonModule, RouterModule],
    templateUrl: './car-manufacturer-jump-dialog.component.html',
    styleUrl: './car-manufacturer-jump-dialog.component.scss'
})
export class CarManufacturerJumpDialogComponent {
  groups: GroupData[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) private data: any) {
    for (const m of this.data) {
      const key = m.name[0].toUpperCase();
      if (this.groups.length > 0) {
        if (this.groups[this.groups.length - 1].name === key) {
          this.groups[this.groups.length - 1].children.push(m);
          continue;
        }
      }
      this.groups.push({
        name: key,
        children: [m],
      });
    }
  }
}
