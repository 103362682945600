import { NgOptimizedImage } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import {
  GooglePlayBadgeComponent,
  AppStoreBadgeComponent,
  AndroidApkBadgeComponent,
} from '@wikylyu/ngx-app-store-badge';
import { Title } from '@angular/platform-browser';
import { CarApiService } from '../../services/car-api.service';
import { RandomCarSwiperComponent } from '../../components/random-car-swiper/random-car-swiper.component';
import { RandomManufacturerSwiperComponent } from '../../components/random-manufacturer-swiper/random-manufacturer-swiper.component';

@Component({
    selector: 'app-home-page',
    imports: [
        MatToolbarModule,
        RouterModule,
        NgOptimizedImage,
        MatButtonModule,
        MatListModule,
        GooglePlayBadgeComponent,
        AppStoreBadgeComponent,
        AndroidApkBadgeComponent,
        RandomCarSwiperComponent,
        RandomManufacturerSwiperComponent,
    ],
    templateUrl: './home-page.component.html',
    styleUrl: './home-page.component.scss'
})
export class HomePageComponent implements OnInit {
  constructor(private title: Title, private api: CarApiService) {
    this.title.setTitle('Horizon 5 Cars - Forza Horizon 5 Car List');
  }

  dbinfo: any;

  ngOnInit(): void {
    this.getDBInfo();
  }

  async getDBInfo() {
    try {
      this.dbinfo = await this.api.getDBInfo();
    } catch (error) {}
  }
}
