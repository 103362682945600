import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-car-rarity-banner',
    imports: [NgClass],
    templateUrl: './car-rarity-banner.component.html',
    styleUrl: './car-rarity-banner.component.scss'
})
export class CarRarityBannerComponent {
  @Input() rarity: string = '';
}
