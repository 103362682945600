import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-car-pi-label',
    imports: [NgClass],
    templateUrl: './car-pi-label.component.html',
    styleUrl: './car-pi-label.component.scss'
})
export class CarPiLabelComponent {
  @Input() klass: string = '';
  @Input() pi: number = 0;
}
