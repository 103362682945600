import { NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CarImagePipe } from '../../pipes/car-image.pipe';
import { CarRarityBannerComponent } from '../car-rarity-banner/car-rarity-banner.component';
import { DrivetrainImagePipe } from '../../pipes/drivetrain-image.pipe';
import { CarPiLabelComponent } from '../car-pi-label/car-pi-label.component';
@Component({
    selector: 'app-car-item',
    imports: [
        MatCardModule,
        MatRippleModule,
        MatTooltipModule,
        NgOptimizedImage,
        CarImagePipe,
        DrivetrainImagePipe,
        CarRarityBannerComponent,
        CarPiLabelComponent,
    ],
    templateUrl: './car-item.component.html',
    styleUrl: './car-item.component.scss'
})
export class CarItemComponent {
  @Input({ required: true }) data: any;

  get name() {
    if (this.data.name.startsWith(this.data.manufacturer)) {
      const name = this.data.name.substr(this.data.manufacturer.length);
      return name.trim();
    }
    return this.data.name;
  }
}
